@import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Great+Vibes&family=Patua+One&display=swap');
@mixin mobile {
  @media only screen and (max-width: 520px) {
    @content;
  }
}
@mixin mobile-height {
  @media only screen and (max-height: 670px) {
    @content;
  }
}

@font-face {
  font-family: myFirstFont;
  src: url(../fonts/vultures.otf);
}

.welcome {
  transition: .3s;
  background-image: url("../img/background-web.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  color: #1a82a5;
  padding-top: 40vh;
  opacity: 1;
  transition: all .5s ease;
  visibility: visible;

  h1 {
    font-family: 'Great Vibes', cursive;
    font-size: 4em;
    text-shadow: 0 0 3px white;
    -webkit-text-stroke: 0.5px white;
    @include mobile {
      font-size: 3em;
    }
  }

  h3 {
    font-family: 'Patua One', cursive;
    text-shadow: 0 0 1.5px white;
    -webkit-text-stroke: 0.1px white;
    font-size: 20px;
  }

  h4 {
    color: #1a82a5;
    font-family: 'Patua One', cursive;
    -webkit-text-stroke: 1px white;
    font-size: 20px;
  }

  .btn-light {
    background-color: #1a82a5;
    color: #eaf3f0;
    font-family: 'Patua One', cursive;
    border: none;
    padding: 6px 30px;
    border-radius: 25px;

    &:hover {
      color: #5d5d5d;
    }
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  img {
    border-radius: 100px;
    box-shadow: 2px 2px 5px #1a82a5;
  }
}

.wedding {
  display: none;

  &.show {
    display: block;
  }
}

.header {
  background-image: url("../img/background-web.webp");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding-top: 35vh;
  color: #1a82a5;
  text-shadow: 1px 1px 5px white;
  -webkit-text-stroke: 0.5px white;

  h5 {
    font-family: 'Patua One', cursive;
    @include mobile {
      font-size: 16px;
    }
  }

  h4 {
    font-family: 'Patua One', cursive;
    @include mobile {
      font-size: 20px;
    }
  }

  h3 {
    font-family: 'Great Vibes', cursive;
    text-shadow: 0 3px 2px white;
    // -webkit-text-stroke: 0.1px white;
    font-size: 5em;
    @include mobile {
      font-size: 3em;
    }
  }
}

.event {
  background-image: url("../img/background-event.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  color: #1a82a5;

  h1 {
    font-family: 'Patua One', cursive;
    text-shadow: 0 1px 10px white;
    font-size: 25px;
  }

  h4 {
    font-size: 20px;
    font-family: 'Patua One', cursive;
    text-shadow: 0 1px 10px white;
  }

  h3 {
    font-family: 'Patua One', cursive;
    text-shadow: 0 1px 10px white;
  }

  p {
    text-shadow: 0px 0px 10px white
  }

  #wedding-day, #wedding-hour, #wedding-min, #wedding-sec, #wedding-time {
    font-family: 'Patua One', cursive;
  }

  &.hidden {
    opacity: 0;
  }

  .btn-primary {
    background-color: white;
    color: #1a82a5;
    font-size: 15px;
    border: none;
    padding: 5px 30px;
    border-radius: 25px;

    &:hover {
      background-color: #1a82a5;
    }
  }

  .digital-envelope {
    font-family: 'Great Vibes', cursive;
    text-shadow: 0 0 3px white;
    font-size: 50px;
  }
}
